
import { Component } from 'vue-property-decorator';
import { IUserProfileUpdate } from '@/interfaces';
import { dispatchGetUsers, dispatchUpdateUser, dispatchGetCustomers } from '@/store/admin/actions';
import { readAdminOneUser, readCustomers } from '@/store/admin/getters';
import { readSites, readUserProfile, readSiteCode } from '@/store/main/getters';
import VueBase from '@/components/VueBase.vue';

@Component
export default class EditUser extends VueBase {
  public valid = true;
  public fullName: string = '';
  public email: string = '';
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';
  public siteCode: string = '';
  public customerId: string = '';

  public async mounted() {
    await dispatchGetUsers(this.$store);
    await dispatchGetCustomers(this.$store);
    // await dispatchGetUserProfile(this.$store);
    this.reset();
  }

  public reset() {
    this.setPassword = false;
    this.password1 = '';
    this.password2 = '';
    this.$validator.reset();
    if (this.user) {
      this.fullName = this.user.full_name;
      this.email = this.user.email;
      this.isActive = this.user.is_active;
      this.isSuperuser = this.user.is_superuser;
      if (this.user.site && this.user.site.code) {
        this.siteCode = this.user.site.code;
      } else {
        this.siteCode = '';
      }
      if (this.user.customer) {
        this.customerId = this.user.customer.id;
      } else {
        this.customerId = '';
      }
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileUpdate = {};
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_superuser = this.isSuperuser;
      if (this.setPassword) {
        updatedProfile.password = this.password1;
      }
      updatedProfile.site_code = this.siteCode ? this.siteCode : null;
      updatedProfile.customer_id = this.customerId ? this.customerId : null;
      await dispatchUpdateUser(this.$store, { id: this.user!.id, user: updatedProfile });
      this.$router.push(`${this.prefixSiteUrl}/main/admin/users`);
    }
  }

  get user() {
    return readAdminOneUser(this.$store)(+this.$router.currentRoute.params.id);
  }

  get sites() {
    return readSites(this.$store);
  }
  get customers() {
    return readCustomers(this.$store);
  }
  get hasCustomer() {
    const profile = readUserProfile(this.$store);
    return profile && profile.customer;
  }
}
